import { flow, SnapshotIn, types } from 'mobx-state-tree';
import ApiStatus from './enums/ApiStatus';
import { customerContactService } from 'services/customerContactService';
import CustomerContact from 'state/models/common/CustomerContactModel';
import axios from 'axios';

export const CustomerContactStore = types
    .model({
        updateCustomerContactApiStatus: types.enumeration<ApiStatus>('ApiStatus', Object.values(ApiStatus)),
    })
    .actions((self) => ({
        update: flow(function* update(customerContact: CustomerContact) {
            try {
                self.updateCustomerContactApiStatus = ApiStatus.Pending;
                const api = customerContactService();

                const response = yield api.apiRenewalsEmailUpdatePost({
                    EmailAddress: customerContact.emailAddress,
                });

                if (response.status === 200) {
                    self.updateCustomerContactApiStatus = ApiStatus.Success;
                } else {
                    throw new Error('update customer contact was not successful');
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    self.updateCustomerContactApiStatus = ApiStatus.Error;
                }
            }
        }),
    }));

export interface ICustomerContact extends SnapshotIn<typeof CustomerContactStore> {}

export const customerContactInitialState: ICustomerContact = {
    updateCustomerContactApiStatus: ApiStatus.Pending,
};
