import { makeStyles } from '@material-ui/core';
import { UiAlert } from '@uinsure/storybook';

const useStyles = makeStyles({
    noWrap: {
        whiteSpace: 'nowrap',
    },
});
interface Props {
    isAutoRenewal: boolean;
    renewalDisplayDate: string;
}

const RenewalNotification = ({ isAutoRenewal, renewalDisplayDate }: Props) => {
    const classes = useStyles();
    const severity = isAutoRenewal ? 'success' : 'error';
    const displayMessage = isAutoRenewal ? (
        <>
            Your policy will automatically renew at 00:01 on{' '}
            <span className={classes.noWrap}>{renewalDisplayDate}</span>.
        </>
    ) : (
        <>
            Your policy will end at 00:01 on <span className={classes.noWrap}>{renewalDisplayDate}</span> and you must{' '}
            <strong>confirm</strong> if you want to renew it.
        </>
    );
    return (
        <UiAlert data-testid='renewal-notification' severity={severity}>
            {displayMessage}
        </UiAlert>
    );
};

export default RenewalNotification;
