import {
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    Grid,
    Hidden,
    Link,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import {
    AlertEndIcon,
    BackgroundContainer,
    DownloadIcon,
    GridTable,
    LabelValueDisplay,
    PageHeading,
    PremiumSummary,
    Sandwich,
} from '@uinsure/storybook';
import CustomerContactDetailsModal, {
    CustomerContactDetailsFormModel,
} from 'components/modals/CustomerContactDetailsModal';
import { OptIn } from 'components/OptIn';
import RenewalNotification from 'components/RenewalNotification';
import SpecifiedItemsTable from 'components/SpecifiedItemsTable';
import UnderwrittenBy from 'components/UnderwrittenBy';
import { useMobile } from 'hooks/useMobile';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { hasConfirmedEmailAddress, setEmailAddressConfirmedCookie } from 'services/customerContactService';
import { getSessionStorage } from 'services/sessionStorageService';
import Layout from '../components/Layout';
import '../extensions/Boolean.extensions';
import '../extensions/Date.extensions';
import '../extensions/Number.extensions';
import ApiStatus from '../state/enums/ApiStatus';
import { useRootStoreContext } from '../state/rootStore';

const useStyles = makeStyles((theme: Theme) => ({
    policyDetailHeading: {
        marginBottom: '1rem',
    },
    sectionHeadingContainer: {
        borderRadius: '7px',
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1.75, 3),
        margin: theme.spacing(5.5, 0, 1.25, 0),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4.5),
            paddingRight: theme.spacing(4.5),
        },
        '& > h2': {
            color: '#fff',
        },
    },
    pageHeading: {
        marginBottom: theme.spacing(3),
    },
    sandwichContainer: {
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(3),
        },
    },
    summarySectionCard: {
        padding: theme.spacing(1.5, 2.5),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1.5, 4),
        },
    },
    layout: {
        paddingBottom: theme.spacing(2),
    },
    labelValueDisplay: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            '& div div:last-child': {
                flex: '0.8',
            },
        },
        '& > div:last-child': {
            marginBottom: 0,
        },
    },
}));

const Summary = observer(() => {
    const classes = useStyles();
    const { homeInsuranceQuote: quote, download, customerContact } = useRootStoreContext();
    const [isContactDetailsModalOpen, setContactDetailsModalOpen] = useState<boolean>(false);

    const handleDocumentDownloadClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        download.downloadRenewal(quote.quoteReference, quote.applicantDetails.applicant1.lastName);
    };

    const handleOptInChoice = (optedIn: boolean) => {
        const linkSource = getSessionStorage('utm_source');
        quote.optedInToDigitalDocuments.changeOptedInToDigitalDocuments(optedIn, linkSource);

        if (optedIn && hasConfirmedEmailAddress() === false) {
            setContactDetailsModalOpen(true);
        }
    };

    const handleContactDetailsSubmit = (values: CustomerContactDetailsFormModel): void => {
        setEmailAddressConfirmedCookie(quote.renewalInceptionDate);
        setContactDetailsModalOpen(false);

        customerContact.update({
            emailAddress: values.emailAddress,
        });
    };

    useEffect(() => {
        quote.getRenewalDetails();
        quote.optedInToDigitalDocuments.fetchOptedInToDigitalDocuments();
        setContactDetailsModalOpen(
            quote.optedInToDigitalDocuments.optedInToDigitalDocuments && hasConfirmedEmailAddress() === false,
        );
    }, [quote.optedInToDigitalDocuments.optedInToDigitalDocuments]);

    const { isMobileDevice } = useMobile('sm');

    const totalMonthlyPremium = quote.quotePremium.totalMonthlyPremium;
    const totalAmountPayable = quote.quotePremium.totalAmountPayable;
    const buildingsCoverLimit = quote.coverOptions.buildingsCoverLimit;
    const contentsCoverLimit = quote.coverOptions.contentsCoverLimit;
    const creditCharge = quote.quotePremium.creditCharge;

    if (quote.getRenewalDetailsApiStatus === ApiStatus.Pending) {
        return <>Loading</>;
    }

    if (quote.getRenewalDetailsApiStatus === ApiStatus.Error) {
        return <Navigate to='/get-in-touch' />;
    }

    return (
        <Layout>
            <OptIn
                onOptInChoice={handleOptInChoice}
                isOpen={quote.optedInToDigitalDocuments.showOptIn}
                settingOptInChoiceHasError={quote.optedInToDigitalDocuments.setOptInChoiceHasError}
            />
            <CustomerContactDetailsModal
                isOpen={isContactDetailsModalOpen}
                onSubmit={handleContactDetailsSubmit}
                emailAddress={quote.applicantDetails.applicant1.emailAddress}
            />
            <Box>
                <PageHeading
                    className={classes.pageHeading}
                    data-testid='summary-page-heading'
                    variant='h1'
                    align='left'
                >
                    Home Insurance Renewal Summary
                </PageHeading>
                <Divider />
                <Box py={2.5}>
                    <UnderwrittenBy underwriterKey={quote.quotePremium.provider} />
                </Box>
                {quote.renewalInceptionDate && (
                    <Box>
                        <RenewalNotification
                            isAutoRenewal={!quote.doNotAutoRenew}
                            renewalDisplayDate={quote.renewalInceptionDate.formatAsDdmmyyyy()}
                        />
                    </Box>
                )}
                <Box py={3}>
                    <BackgroundContainer
                        data-testid='premium-summary'
                        content1={
                            <PremiumSummary
                                isPayingMonthly={quote.quotePremium.isPayingMonthly}
                                isPremiumCheaperThanLastYear={quote.quotePremium.isCheaperThanLastYearsPremium}
                                premium={
                                    quote.quotePremium.isPayingMonthly
                                        ? totalMonthlyPremium.currencyWithThousandSeparators(true)
                                        : totalAmountPayable.currencyWithThousandSeparators(true)
                                }
                                premiumDifferenceAgainstLastYear={quote.quotePremium.premiumDifferenceAgainstLastYear.currencyWithThousandSeparators(
                                    true,
                                )}
                                totalAmountPayable={totalAmountPayable.currencyWithThousandSeparators(true)}
                            />
                        }
                        content2={
                            <Grid container spacing={1} justifyContent='center'>
                                <Grid item xs={8} sm={7} md={5}>
                                    <Button
                                        title='Download'
                                        variant='outlined'
                                        color='default'
                                        disabled={download.isLoading}
                                        onClick={handleDocumentDownloadClick}
                                        endIcon={download.isLoading ? <CircularProgress size={20} /> : <DownloadIcon />}
                                    >
                                        <span>Download</span>
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    {download.hasError && (
                                        <Typography color='error' align='center'>
                                            Oops something went wrong. Please call 0330 100 9602
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={8} sm={7} md={5}>
                                    <Link
                                        color='textPrimary'
                                        target={isMobileDevice ? '' : '_blank'}
                                        href={
                                            isMobileDevice
                                                ? 'tel:03301009602'
                                                : 'https://uinsure.co.uk/contact-us-customer/'
                                        }
                                        underline='none'
                                    >
                                        <Button
                                            title='Contact us'
                                            variant={quote.doNotAutoRenew ? 'contained' : 'outlined'}
                                            color={quote.doNotAutoRenew ? 'primary' : 'default'}
                                            endIcon={quote.doNotAutoRenew && <AlertEndIcon />}
                                        >
                                            <span>
                                                Contact us
                                                {quote.doNotAutoRenew && (
                                                    <>
                                                        <br /> to renew
                                                    </>
                                                )}
                                            </span>
                                        </Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        }
                        alignItems='center'
                    />
                </Box>
            </Box>
            <Box className={classes.sandwichContainer}>
                <Sandwich data-testid='sandwich-make-a-change'>
                    <Typography align='center'>Need to make a change? Please contact us</Typography>
                </Sandwich>
            </Box>
            <Hidden smUp>
                <Box data-testid='policy-cover-details-heading' className={classes.sectionHeadingContainer}>
                    <Typography component='h2' variant='h4'>
                        Policy cover details
                    </Typography>
                </Box>
            </Hidden>
            <GridTable>
                <Box px={1}>
                    <Typography component='h3' variant='h5' className={classes.policyDetailHeading}>
                        <strong>Buildings cover</strong>
                    </Typography>
                    <LabelValueDisplay
                        label='Your buildings cover of'
                        value={quote.coverOptions.buildingsIncluded.toReadableString(
                            `£${buildingsCoverLimit.currencyWithThousandSeparators()}`,
                            'Not included',
                        )}
                    />
                    <LabelValueDisplay
                        label='Accidental damage'
                        value={(
                            quote.coverOptions.buildingsIncluded && quote.coverOptions.buildingsAccidentalDamageIncluded
                        ).toReadableString('Included', 'Not included')}
                    />
                    <LabelValueDisplay
                        label='No claims discount of'
                        value={quote.coverOptions.buildingsIncluded.toReadableString(
                            quote.coverOptions.buildingsClaimFreeYearsText,
                            'Not included',
                        )}
                    />
                </Box>
                <Box px={1}>
                    <Typography component='h3' variant='h5' className={classes.policyDetailHeading}>
                        <strong>Contents cover</strong>
                    </Typography>
                    <LabelValueDisplay
                        label='Your contents cover of'
                        value={quote.coverOptions.contentsIncluded.toReadableString(
                            `£${contentsCoverLimit.currencyWithThousandSeparators()}`,
                            'Not included',
                        )}
                    />
                    <LabelValueDisplay
                        label='Accidental damage'
                        value={(
                            quote.coverOptions.contentsIncluded && quote.coverOptions.contentsAccidentalDamageIncluded
                        ).toReadableString('Included', 'Not included')}
                    />
                    <LabelValueDisplay
                        label='No claims discount of'
                        value={quote.coverOptions.contentsClaimFreeYearsText}
                    />
                    <LabelValueDisplay
                        label='Unspecified cover amount'
                        value={quote.coverOptions.personalPossessionsValue}
                    />
                </Box>
                <Box px={1}>
                    <Typography component='h3' variant='h5' className={classes.policyDetailHeading}>
                        <strong>Excess information</strong>
                    </Typography>
                    <LabelValueDisplay
                        label='Buildings excess'
                        value={quote.coverOptions.buildingsIncluded.toReadableString(
                            `£${quote.coverOptions.buildingsExcess}`,
                            'Not included',
                        )}
                    />
                    <LabelValueDisplay label='Contents excess' value={`£${quote.coverOptions.contentsExcess}`} />
                    <LabelValueDisplay label='Escape of water excess' value='£350' />
                    <LabelValueDisplay label='Subsidence excess' value='£1,000' />
                </Box>
                <Box px={1}>
                    <Typography component='h3' variant='h5' className={classes.policyDetailHeading}>
                        <strong>Additional cover</strong>
                    </Typography>
                    <LabelValueDisplay
                        label='Home Emergency'
                        value={quote.coverOptions.homeEmergencyCoverIncluded.toReadableString(
                            'Included',
                            'Not included',
                        )}
                    />
                    <LabelValueDisplay
                        label='Legal Protection'
                        value={quote.coverOptions.legalProtectionIncluded.toReadableString('Included', 'Not included')}
                    />
                </Box>
            </GridTable>
            {quote.coverOptions.hasSpecifiedItems && (
                <>
                    <Box data-testid='specified-items-heading' className={classes.sectionHeadingContainer}>
                        <Typography component='h2' variant='h4'>
                            Specified items
                        </Typography>
                    </Box>
                    <SpecifiedItemsTable items={quote.coverOptions.contentsSpecifiedItems} />
                </>
            )}
            <Box data-testid='policyholder-details-heading' className={classes.sectionHeadingContainer}>
                <Typography component='h2' variant='h4'>
                    Policyholder details
                </Typography>
            </Box>
            <Card variant='outlined' className={classes.summarySectionCard}>
                <Box className={classes.labelValueDisplay}>
                    <LabelValueDisplay
                        label='Policyholders'
                        emphasiseLabel
                        emphasisVariant='h5'
                        stack={isMobileDevice}
                        value={
                            <>
                                <Typography>
                                    {quote.applicantDetails.applicant1.fullNameAndTitle} (
                                    {quote.applicantDetails.applicant1.formattedDateOfBirth})
                                </Typography>
                                {quote.applicantDetails.applicant2 && (
                                    <Typography>
                                        {quote.applicantDetails.applicant2.fullNameAndTitle} (
                                        {quote.applicantDetails.applicant2.formattedDateOfBirth})
                                    </Typography>
                                )}
                            </>
                        }
                    />
                    {quote.applicantDetails.applicant1.telephoneNumber && (
                        <Box data-testid='phone-number'>
                            <LabelValueDisplay
                                label='Telephone number'
                                emphasiseLabel
                                emphasisVariant='h5'
                                stack={isMobileDevice}
                                value={quote.applicantDetails.applicant1.telephoneNumber}
                            />
                        </Box>
                    )}

                    <LabelValueDisplay
                        label='Correspondence address'
                        emphasiseLabel
                        emphasisVariant='h5'
                        stack={isMobileDevice}
                        value={quote.correspondenceAddress.fullAddressInline}
                    />
                </Box>
            </Card>

            <Box data-testid='property-details-heading' className={classes.sectionHeadingContainer}>
                <Typography component='h2' variant='h4'>
                    Property details
                </Typography>
            </Box>
            <Card variant='outlined' className={classes.summarySectionCard}>
                <Box className={classes.labelValueDisplay}>
                    <LabelValueDisplay
                        label='Risk address'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.riskAddress.fullAddressInline}
                    />
                    <LabelValueDisplay
                        label='Property type'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.propertyDetails.propertyType}
                    />
                    <LabelValueDisplay
                        label='Year build'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.propertyDetails.yearBuiltRange}
                    />
                    <LabelValueDisplay
                        label='Number of bedrooms'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.propertyDetails.numberOfBedrooms.pluralise('bedroom')}
                    />
                </Box>
            </Card>

            <Box data-testid='renewal-details-heading' className={classes.sectionHeadingContainer}>
                <Typography component='h2' variant='h4'>
                    Renewal details
                </Typography>
            </Box>
            <Card variant='outlined' className={classes.summarySectionCard} data-testid='renewal-details'>
                <Box className={classes.labelValueDisplay}>
                    <LabelValueDisplay
                        label='Policy number'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.accountReference}
                    />
                    <LabelValueDisplay
                        label='Policy start date'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.renewalInceptionDate && quote.renewalInceptionDate.formatAsDdmmyyyy()}
                    />
                    <LabelValueDisplay
                        label='Estimated payment date'
                        emphasisVariant='h5'
                        emphasiseLabel
                        stack={isMobileDevice}
                        value={quote.estimatedFirstPaymentDate}
                    />
                    {quote.quotePremium.isPayingMonthly && quote.quotePremium.isPremiumFunded && (
                        <Box pt={5}>
                            <Typography align='center' data-testid='monthly-instalments-copy'>
                                You currently pay by monthly instalments, you could save{' '}
                                <strong>£{creditCharge.currencyWithThousandSeparators(true)}</strong> by paying
                                annually.
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Card>
        </Layout>
    );
});
export default Summary;
