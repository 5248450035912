import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from '../services/authProvider';
import { useRootStoreContext } from '../state/rootStore';

const ParseCode = observer(() => {
    const { login } = useAuthContext();
    const { authentication } = useRootStoreContext();
    const location = useLocation();

    const code = location.pathname.replace('/login/', '');

    const from = location.state?.from?.pathname;
    authentication.setRedirect(removeCodeFromRedirect(from));

    useEffect(() => {
        if (code) {
            login(code);
        }
    }, [code]);

    return <></>;
});

const removeCodeFromRedirect = (from?: string) => {
    const codeIndex = from?.indexOf('?code') ?? -1;
    if (codeIndex >= 0) {
        return from?.substring(0, codeIndex);
    }
    return from;
};

export default ParseCode;
