Number.prototype.currencyWithThousandSeparators = function (showPence?: boolean): string {
    return Number(this)
        .toFixed(!!showPence ? 2 : 0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

Number.prototype.ordinal = function (): string {
    const number = Number(this);

    if (number < 0) {
        throw new Error('Number can not be less than 0.');
    }

    if (number >= 11 && number <= 13) {
        return 'th';
    }

    const lastDigit = number % 10;

    if (lastDigit == 1) {
        return 'st';
    }

    if (lastDigit == 2) {
        return 'nd';
    }

    if (lastDigit == 3) {
        return 'rd';
    }

    return 'th';
};

Number.prototype.pluralise = function (singular: string, plural?: string): string {
    const value = Number(this);
    const text = value === 1 ? singular : plural || `${singular}s`;
    return `${value} ${text}`;
};

export {};
