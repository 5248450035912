import { SnapshotIn, types } from 'mobx-state-tree';

export const QuotePremium = types
    .model({
        provider: types.string,
        creditCharge: types.number, //This is the difference between paying annually and paying monthly when policy is premium funded
        totalMonthlyPremium: types.number,
        totalAmountPayable: types.number,

        lastYearsPremium: types.number,
        lastYearsMonthlyPremium: types.number,
        lastYearsPremiumWithoutPremiumFunding: types.number,

        isPayingMonthly: types.boolean,
        isPremiumFunded: types.boolean,
    })
    .views((self) => {
        const views = {
            get totalAmountPayableWithoutCreditCharge(): number {
                const totalAmountPayable = self.totalAmountPayable * 100;
                const creditCharge = self.creditCharge * 100;
                return (totalAmountPayable - creditCharge) / 100;
            },
            get premiumDifferenceAgainstLastYear(): number {
                // We need to do our currency calculations with integers to avoid accuracy issues caused by floating-point arithmetic
                // https://stackoverflow.com/questions/588004/is-floating-point-math-broken
                const lastYearsPremiumWithoutPremiumFunding = self.lastYearsPremiumWithoutPremiumFunding * 100;
                const totalAmountPayable = self.totalAmountPayable * 100;
                const creditCharge = self.creditCharge * 100;
                return (lastYearsPremiumWithoutPremiumFunding - totalAmountPayable + creditCharge) / 100;
            },
        };

        return views;
    })
    .views((self) => {
        const views = {
            get isCheaperThanLastYearsPremium(): boolean {
                return self.premiumDifferenceAgainstLastYear > 0;
            },
        };

        return views;
    });

export interface IQuotePremium extends SnapshotIn<typeof QuotePremium> {}

export const quotePremiumInitialState: IQuotePremium = {
    provider: '',
    creditCharge: 0,
    totalMonthlyPremium: 0,
    totalAmountPayable: 0,

    lastYearsPremium: 0,
    lastYearsMonthlyPremium: 0,
    lastYearsPremiumWithoutPremiumFunding: 0,

    isPayingMonthly: false,
    isPremiumFunded: false,
};
