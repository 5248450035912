import { SnapshotIn, types } from 'mobx-state-tree';
import { SpecifiedItem } from './SpecifiedItemStore';
import 'extensions/Number.extensions';

export const CoverOptions = types
    .model({
        buildingsIncluded: types.boolean,
        buildingsCoverLimit: types.number,
        buildingsExcess: types.number,
        buildingsClaimFreeYears: types.number,
        buildingsAccidentalDamageIncluded: types.boolean,

        contentsIncluded: types.boolean,
        contentsCoverLimit: types.number,
        contentsExcess: types.number,
        contentsClaimFreeYears: types.number,
        contentsAccidentalDamageIncluded: types.boolean,

        contentsPersonalPossessionsValue: types.number,
        contentsSpecifiedItems: types.optional(types.array(SpecifiedItem), []),

        homeEmergencyCoverIncluded: types.boolean,
        legalProtectionIncluded: types.boolean,
    })
    .views((self) => {
        const views = {
            get personalPossessionsValue(): string {
                if (self.contentsPersonalPossessionsValue === 0) {
                    return 'Not included';
                } else {
                    return `£${self.contentsPersonalPossessionsValue.currencyWithThousandSeparators()}`;
                }
            },
            get buildingsClaimFreeYearsText(): string {
                return self.buildingsClaimFreeYears.pluralise('Year');
            },
            get contentsClaimFreeYearsText(): string {
                return self.contentsClaimFreeYears.pluralise('Year');
            },
            get hasSpecifiedItems(): boolean {
                return self.contentsIncluded && self.contentsSpecifiedItems.length > 0;
            },
        };

        return views;
    });

export interface ICoverOptions extends SnapshotIn<typeof CoverOptions> {}

export const coverOptionsInitialState: ICoverOptions = {
    buildingsIncluded: true,
    buildingsCoverLimit: 1000000,
    buildingsAccidentalDamageIncluded: false,
    contentsIncluded: false,
    contentsCoverLimit: 75000,
    contentsAccidentalDamageIncluded: false,
    buildingsExcess: 250,
    contentsExcess: 250,
    buildingsClaimFreeYears: 9,
    contentsClaimFreeYears: 9,

    contentsPersonalPossessionsValue: 0,
    contentsSpecifiedItems: [],

    homeEmergencyCoverIncluded: false,
    legalProtectionIncluded: false,
};
