import { TableBody, TableContainer } from '@material-ui/core';
import { UiTable as Table, TableCell, TableHead, TableRow, StatusIcon } from '@uinsure/storybook';
import { ISpecifiedItem } from 'state/models/home-insurance/SpecifiedItemStore';

interface SpecifiedItemsTableProps {
    items: ISpecifiedItem[];
}

const SpecifiedItemsTable = ({ items }: SpecifiedItemsTableProps) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width={'20%'} align='center'>
                            Type
                        </TableCell>
                        <TableCell width={'20%'} align='center'>
                            Item
                        </TableCell>
                        <TableCell width={'20%'} align='center'>
                            Value
                        </TableCell>
                        <TableCell width={'20%'} align='center'>
                            At home
                        </TableCell>
                        <TableCell width={'20%'} align='center'>
                            Away from home
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow data-testid={`specified-item-${index}`} key={`${item.description}-${index}`}>
                            <TableCell data-testid={`specified-item-${index}-type`} align='center' scope='row'>
                                {item.type}
                            </TableCell>
                            <TableCell data-testid={`specified-item-${index}-description`} align='center'>
                                {item.description}
                            </TableCell>
                            <TableCell data-testid={`specified-item-${index}-value`} align='center'>
                                £{item.value.currencyWithThousandSeparators()}
                            </TableCell>
                            <TableCell data-testid={`specified-item-${index}-cover-home`} align='center'>
                                <StatusIcon condition={true} />
                            </TableCell>
                            <TableCell data-testid={`specified-item-${index}-cover-away`} align='center'>
                                <StatusIcon condition={item.coverAwayFromHome} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SpecifiedItemsTable;
