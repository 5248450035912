import React from 'react';
import Layout from '../components/Layout';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    paper: {
        minHeight: '70vh',
    },
}));

const GetInTouch = () => {
    const classes = useStyles();

    return (
        <Layout>
            <Paper elevation={0} className={classes.paper}>
                <Typography align='center' variant='h2' component='h1' data-testid='error-message'>
                    Oops something went wrong. Please try again.
                    <br />
                    <br />
                    If the error persists please contact us on 0330 100 9602
                </Typography>
            </Paper>
        </Layout>
    );
};

export default GetInTouch;
