import { types, SnapshotIn } from 'mobx-state-tree';

export const SpecifiedItem = types.model({
    type: types.string,
    description: types.string,
    value: types.number,
    coverAtHome: types.boolean,
    coverAwayFromHome: types.boolean,
});

export interface ISpecifiedItem extends SnapshotIn<typeof SpecifiedItem> {}

export const specifiedItemInitialState: ISpecifiedItem = {
    type: '',
    description: '',
    value: 0,
    coverAtHome: false,
    coverAwayFromHome: false,
};
