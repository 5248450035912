import { SnapshotIn, types } from 'mobx-state-tree';
import ApplicantDto from './ApplicantModel';
import 'extensions/Number.extensions';

// models
const ApplicantModel = {
    title: types.string,
    firstName: types.string,
    lastName: types.string,
    dateOfBirth: types.maybeNull(types.Date),
    //Allows us to define optional fields and provide defaults
    emailAddress: types.optional(types.string, ''),
    telephoneNumber: types.optional(types.string, ''),
};

export const Applicant = types
    .model(ApplicantModel)
    // https://mobx-state-tree.js.org/concepts/actions
    // actions - Unlike MobX, MST only supports the mutation of a node through an action
    // attempting to mutate a node without an action will result in an exception
    // 'this' cannot be used in actions, 'self' is bound to the current instance
    .actions((self) => ({
        setDetails(details: ApplicantDto) {
            self.title = details.Title;
            self.firstName = details.FirstName;
            self.lastName = details.LastName;
            self.dateOfBirth = details.DateOfBirth;
            self.emailAddress = details.EmailAddress;
            self.telephoneNumber = details.TelephoneNumber;
        },
    }))
    .views((self) => {
        const views = {
            get fullNameAndTitle(): string {
                return `${self.title} ${self.firstName} ${self.lastName}`;
            },

            get formattedDateOfBirth(): string {
                if (self.dateOfBirth) {
                    return self.dateOfBirth.formatAsDdmmyyyy();
                }

                throw new Error('Date of birth is undefined');
                // return '01 / 01 / 2000';
            },
        };

        return views;
    });

export const ApplicantDetails = types.model({
    applicant1: Applicant,
    applicant2: types.maybeNull(Applicant),
});

const applicantInitialState: IApplicant = {
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: undefined,
};

const applicantDetailsInitialState: IApplicantDetails = {
    applicant1: Applicant.create(applicantInitialState),
};

export interface IApplicant extends SnapshotIn<typeof Applicant> {}
export interface IApplicantDetails extends SnapshotIn<typeof ApplicantDetails> {}

export { applicantInitialState, applicantDetailsInitialState };
