const stsAuthority = process.env.REACT_APP_ISSUER_URL;

export const isBrowser = () => typeof window !== 'undefined';

export const getAccessToken = () => {
    if (isBrowser()) {
        const sessionKey = `oidc.user:${stsAuthority}:customer-portal-spa`;
        var raw = sessionStorage.getItem(sessionKey);
        if (raw) {
            var jwt = JSON.parse(raw);
            return jwt.access_token;
        }
    }
    return '';
};

export const isLoggedIn = () => {
    const accessToken = getAccessToken();
    return accessToken ? true : false;
};
