import { SnapshotIn, types } from 'mobx-state-tree';

interface AuthenticationModel {
    isAuthenticated: boolean;
    contact: string;
    redirectTo: string;
}

const AuthenticationModel = {
    isAuthenticated: types.boolean,
    contact: types.string,
    redirectTo: types.string,
};

export const AuthenticationStore = types
    .model(AuthenticationModel)
    .actions((self) => ({
        authenticate(contact: string) {
            self.isAuthenticated = true;
            self.contact = contact;
        },
        setRedirect(to?: string) {
            if (to !== undefined) {
                self.redirectTo = to;
            }
        },
    }))
    .views((self) => {
        const views = {};

        return views;
    });

const initialState: IAuthentication = {
    isAuthenticated: false,
    contact: '',
    redirectTo: '/',
};

export interface IAuthentication extends SnapshotIn<typeof AuthenticationStore> {}

export { initialState as authenticationInitialState };
