import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { getParam } from 'services/urlParamService';
import { isLoggedIn } from '../services/auth';

const RequireAuth = () => {
    let location = useLocation();

    if (!isLoggedIn()) {
        const code = getParam('code', location) ?? '';
        const encoded = encodeURI(code);
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={`/login/${encoded}`} state={{ from: location }} replace />;
    }

    return <Outlet />;
};

export default RequireAuth;
