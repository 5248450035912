import { types, SnapshotIn } from 'mobx-state-tree';

export const PropertyDetails = types.model({
    propertyType: types.string,
    numberOfBedrooms: types.number,
    yearBuiltRange: types.string,
    yearBuilt: types.maybe(types.string),
});

export interface IPropertyDetails extends SnapshotIn<typeof PropertyDetails> {}

export const propertyDetailsInitialState: IPropertyDetails = {
    numberOfBedrooms: 0,
    propertyType: '',
    yearBuiltRange: '',
};
