/* tslint:disable */
/* eslint-disable */
/**
 * Uinsure.CustomerPortal.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'AddressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'AddressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'AddressLine3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'AddressLine4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'Postcode'?: string | null;
}
/**
 * 
 * @export
 * @interface Applicant
 */
export interface Applicant {
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'Title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'FirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'LastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'DateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'TelephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Applicant
     */
    'EmailAddress'?: string | null;
}
/**
 * Represents a request from a customer to either opt in or out of digital renewals
 * @export
 * @interface OptInRequest
 */
export interface OptInRequest {
    /**
     * Whether or not the customer has opted in
     * @type {boolean}
     * @memberof OptInRequest
     */
    'OptedIn'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OptInRequest
     */
    'LinkSource'?: string | null;
}
/**
 * 
 * @export
 * @interface QuotePremium
 */
export interface QuotePremium {
    /**
     * 
     * @type {string}
     * @memberof QuotePremium
     */
    'Provider'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotePremium
     */
    'CreditCharge'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotePremium
     */
    'TotalMonthlyPremium'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotePremium
     */
    'TotalAmountPayable'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotePremium
     */
    'LastYearsPremium'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotePremium
     */
    'LastYearsPremiumWithoutPremiumFunding'?: number;
    /**
     * 
     * @type {number}
     * @memberof QuotePremium
     */
    'LastYearsMonthlyPremium'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof QuotePremium
     */
    'IsPayingMonthly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuotePremium
     */
    'IsPremiumFunded'?: boolean;
}
/**
 * 
 * @export
 * @interface RenewalDetails
 */
export interface RenewalDetails {
    /**
     * 
     * @type {Applicant}
     * @memberof RenewalDetails
     */
    'Client1'?: Applicant;
    /**
     * 
     * @type {Applicant}
     * @memberof RenewalDetails
     */
    'Client2'?: Applicant;
    /**
     * 
     * @type {Address}
     * @memberof RenewalDetails
     */
    'RiskAddress'?: Address;
    /**
     * 
     * @type {string}
     * @memberof RenewalDetails
     */
    'PropertyType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'NumberOfBedrooms'?: number;
    /**
     * 
     * @type {string}
     * @memberof RenewalDetails
     */
    'YearOfConstructionRange'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'BuildingsCoverIncluded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'BuildingsAccidentalDamageIncluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'BuildingsCoverLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'BuildingsExcess'?: number;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'BuildingsNoClaimsYears'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'ContentsCoverIncluded'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'ContentsAccidentalDamageIncluded'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'ContentsCoverLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'ContentsExcess'?: number;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'ContentsNoClaimsYears'?: number;
    /**
     * 
     * @type {Array<SpecifiedItem>}
     * @memberof RenewalDetails
     */
    'ContentsSpecifiedItems'?: Array<SpecifiedItem> | null;
    /**
     * 
     * @type {number}
     * @memberof RenewalDetails
     */
    'ContentsPersonalPossessionsValue'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'HomeEmergencyCover'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'FamilyLegalProtection'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RenewalDetails
     */
    'QuoteReference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenewalDetails
     */
    'PolicyStartDate'?: string;
    /**
     * 
     * @type {Address}
     * @memberof RenewalDetails
     */
    'CorrespondenceAddress'?: Address;
    /**
     * 
     * @type {QuotePremium}
     * @memberof RenewalDetails
     */
    'QuotePremium'?: QuotePremium;
    /**
     * 
     * @type {boolean}
     * @memberof RenewalDetails
     */
    'DoNotAutoRenew'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RenewalDetails
     */
    'RenewalInceptionDate'?: string;
}
/**
 * 
 * @export
 * @interface SpecifiedItem
 */
export interface SpecifiedItem {
    /**
     * 
     * @type {string}
     * @memberof SpecifiedItem
     */
    'Type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SpecifiedItem
     */
    'Description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpecifiedItem
     */
    'Value'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SpecifiedItem
     */
    'CoverAtHome'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SpecifiedItem
     */
    'CoverAwayFromHome'?: boolean;
}
/**
 * Represents a request from a customer to change their email address
 * @export
 * @interface UpdateEmailAddress
 */
export interface UpdateEmailAddress {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmailAddress
     */
    'EmailAddress'?: string | null;
}

/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary An additional healthcheck endpoint that validates an authorized request with api-read permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working , and otherwise return \"not OK\" when it\'s not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCheckAuthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/HealthCheck/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary An additional healthcheck endpoint that validates an authorized request with api-write permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working ,   and otherwise return \"not OK\" when it\'s not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCheckAuthPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/HealthCheck/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary The default endpoint.    This is the default health check endpoint that can be used to assure that the system is correctly configured  and recieving HTTP requests.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working, and otherwise return \"not OK\"  if something is misconfigured but it can still handle the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/HealthCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.write", "uinsure.api.customer.read"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary An additional healthcheck endpoint that validates an authorized request with api-read permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working , and otherwise return \"not OK\" when it\'s not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthCheckAuthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHealthCheckAuthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary An additional healthcheck endpoint that validates an authorized request with api-write permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working ,   and otherwise return \"not OK\" when it\'s not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthCheckAuthPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHealthCheckAuthPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary The default endpoint.    This is the default health check endpoint that can be used to assure that the system is correctly configured  and recieving HTTP requests.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working, and otherwise return \"not OK\"  if something is misconfigured but it can still handle the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiHealthCheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiHealthCheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckApiFp(configuration)
    return {
        /**
         * 
         * @summary An additional healthcheck endpoint that validates an authorized request with api-read permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working , and otherwise return \"not OK\" when it\'s not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCheckAuthGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiHealthCheckAuthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary An additional healthcheck endpoint that validates an authorized request with api-write permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working ,   and otherwise return \"not OK\" when it\'s not.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCheckAuthPost(options?: any): AxiosPromise<string> {
            return localVarFp.apiHealthCheckAuthPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary The default endpoint.    This is the default health check endpoint that can be used to assure that the system is correctly configured  and recieving HTTP requests.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working, and otherwise return \"not OK\"  if something is misconfigured but it can still handle the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHealthCheckGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiHealthCheckGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * 
     * @summary An additional healthcheck endpoint that validates an authorized request with api-read permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working , and otherwise return \"not OK\" when it\'s not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public apiHealthCheckAuthGet(options?: AxiosRequestConfig) {
        return HealthCheckApiFp(this.configuration).apiHealthCheckAuthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary An additional healthcheck endpoint that validates an authorized request with api-write permission.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working ,   and otherwise return \"not OK\" when it\'s not.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public apiHealthCheckAuthPost(options?: AxiosRequestConfig) {
        return HealthCheckApiFp(this.configuration).apiHealthCheckAuthPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary The default endpoint.    This is the default health check endpoint that can be used to assure that the system is correctly configured  and recieving HTTP requests.    This healthcheck endpoint will return \"ok\" when everything is assumed to be working, and otherwise return \"not OK\"  if something is misconfigured but it can still handle the request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public apiHealthCheckGet(options?: AxiosRequestConfig) {
        return HealthCheckApiFp(this.configuration).apiHealthCheckGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RenewalsApi - axios parameter creator
 * @export
 */
export const RenewalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Updates the email address for the policy renewal.
         * @param {UpdateEmailAddress} [updateEmailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsEmailUpdatePost: async (updateEmailAddress?: UpdateEmailAddress, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Renewals/email/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmailAddress, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the opt in choice for the policy renewal.
         * @param {OptInRequest} [optInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsOptInPost: async (optInRequest?: OptInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Renewals/opt-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(optInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RenewalsApi - functional programming interface
 * @export
 */
export const RenewalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RenewalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Updates the email address for the policy renewal.
         * @param {UpdateEmailAddress} [updateEmailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRenewalsEmailUpdatePost(updateEmailAddress?: UpdateEmailAddress, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRenewalsEmailUpdatePost(updateEmailAddress, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the opt in choice for the policy renewal.
         * @param {OptInRequest} [optInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRenewalsOptInPost(optInRequest?: OptInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRenewalsOptInPost(optInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RenewalsApi - factory interface
 * @export
 */
export const RenewalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RenewalsApiFp(configuration)
    return {
        /**
         * 
         * @summary Updates the email address for the policy renewal.
         * @param {UpdateEmailAddress} [updateEmailAddress] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsEmailUpdatePost(updateEmailAddress?: UpdateEmailAddress, options?: any): AxiosPromise<void> {
            return localVarFp.apiRenewalsEmailUpdatePost(updateEmailAddress, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the opt in choice for the policy renewal.
         * @param {OptInRequest} [optInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsOptInPost(optInRequest?: OptInRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiRenewalsOptInPost(optInRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RenewalsApi - object-oriented interface
 * @export
 * @class RenewalsApi
 * @extends {BaseAPI}
 */
export class RenewalsApi extends BaseAPI {
    /**
     * 
     * @summary Updates the email address for the policy renewal.
     * @param {UpdateEmailAddress} [updateEmailAddress] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalsApi
     */
    public apiRenewalsEmailUpdatePost(updateEmailAddress?: UpdateEmailAddress, options?: AxiosRequestConfig) {
        return RenewalsApiFp(this.configuration).apiRenewalsEmailUpdatePost(updateEmailAddress, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the opt in choice for the policy renewal.
     * @param {OptInRequest} [optInRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalsApi
     */
    public apiRenewalsOptInPost(optInRequest?: OptInRequest, options?: AxiosRequestConfig) {
        return RenewalsApiFp(this.configuration).apiRenewalsOptInPost(optInRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RenewalsQueryApi - axios parameter creator
 * @export
 */
export const RenewalsQueryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the renewal details for a customer\'s policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsDetailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Renewals/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a renewal document for a given policy number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsDownloadGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Renewals/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the opted in choice for the policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsOptInGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Renewals/opt-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["openid", "profile", "email", "uinsure.api.customer.read", "uinsure.api.customer.write"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RenewalsQueryApi - functional programming interface
 * @export
 */
export const RenewalsQueryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RenewalsQueryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the renewal details for a customer\'s policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRenewalsDetailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RenewalDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRenewalsDetailsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download a renewal document for a given policy number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRenewalsDownloadGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRenewalsDownloadGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the opted in choice for the policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRenewalsOptInGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRenewalsOptInGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RenewalsQueryApi - factory interface
 * @export
 */
export const RenewalsQueryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RenewalsQueryApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the renewal details for a customer\'s policy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsDetailsGet(options?: any): AxiosPromise<RenewalDetails> {
            return localVarFp.apiRenewalsDetailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a renewal document for a given policy number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsDownloadGet(options?: any): AxiosPromise<File> {
            return localVarFp.apiRenewalsDownloadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the opted in choice for the policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRenewalsOptInGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiRenewalsOptInGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RenewalsQueryApi - object-oriented interface
 * @export
 * @class RenewalsQueryApi
 * @extends {BaseAPI}
 */
export class RenewalsQueryApi extends BaseAPI {
    /**
     * 
     * @summary Gets the renewal details for a customer\'s policy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalsQueryApi
     */
    public apiRenewalsDetailsGet(options?: AxiosRequestConfig) {
        return RenewalsQueryApiFp(this.configuration).apiRenewalsDetailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a renewal document for a given policy number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalsQueryApi
     */
    public apiRenewalsDownloadGet(options?: AxiosRequestConfig) {
        return RenewalsQueryApiFp(this.configuration).apiRenewalsDownloadGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the opted in choice for the policy.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RenewalsQueryApi
     */
    public apiRenewalsOptInGet(options?: AxiosRequestConfig) {
        return RenewalsQueryApiFp(this.configuration).apiRenewalsOptInGet(options).then((request) => request(this.axios, this.basePath));
    }
}


