Date.prototype.formatAsDdmmyyyy = function (): string {
    var dd = String(this.getDate()).padStart(2, '0');
    var mm = String(this.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.getFullYear();

    return `${dd} / ${mm} / ${yyyy}`;
};

Date.prototype.addDays = function (days: number): Date {
    const date = this ?? new Date();
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.isSameOrAfter = function (date: Date): boolean {
    const selfDate = this ?? new Date();

    return selfDate.getTime() >= date.getTime();
};

export {};
