import { useEffect } from 'react';
import { useAuthContext } from '../services/authProvider';
import { observer } from 'mobx-react';

import { useNavigate } from 'react-router-dom';
import { useRootStoreContext } from '../state/rootStore';

interface Props {
    code?: string;
}

const SigninCallback = observer(({ code }: Props) => {
    const { signinRedirectCallback, decodeJwt } = useAuthContext();
    const navigate = useNavigate();
    const { authentication } = useRootStoreContext();

    useEffect(() => {
        signinRedirectCallback((user: any) => {
            const obj = decodeJwt(user.access_token);
            if (!code) {
                navigate('/');
            }
            authentication.authenticate(obj.sub);
            navigate(authentication.redirectTo);
        });
    }, []);

    return <></>;
});

export default SigninCallback;
