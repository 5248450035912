import { AppBar, Box, createStyles, Grid, Hidden, makeStyles, Theme, Toolbar } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Logo } from 'assets/logo.svg';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        offset: {
            ...theme.mixins.toolbar,
        },
        headerLogo: {
            marginLeft: theme.spacing(2),
        },
    });
});

const Header = () => {
    const classes = useStyles();
    return (
        <>
            <AppBar>
                <Grid container alignItems='center'>
                    <Logo className={classes.headerLogo} />
                    <Hidden xsDown>
                        <Toolbar />
                    </Hidden>
                </Grid>
            </AppBar>
            <div className={classes.offset} />
        </>
    );
};

export default Header;
