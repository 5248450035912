import { User } from 'oidc-client';
import React, { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from './authService';

let authService = new AuthService();

interface AuthContextProps {
    login: (code: string) => Promise<void>;
    logout: () => Promise<void>;
    signinRedirectCallback: (callback: any) => Promise<void>;
    silentRenew: () => Promise<User | void>;
    decodeJwt: (code: string) => any;
}

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthContext = createContext({} as AuthContextProps);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const navigate = useNavigate();

    const value: AuthContextProps = {
        login: async (code: string) => {
            authService = new AuthService(code);
            return await authService.login();
        },
        logout: () => authService.logout(),
        signinRedirectCallback: (callback: any) =>
            authService
                .userManager!.signinRedirectCallback()
                .then((user: any) => callback(user))
                .catch((error: Error) => {
                    console.error(error);
                    if (error.message == 'No matching state found in storage') {
                        // People bookmark the IDP page so end up here.
                        // We need to trigger the session init functionality as if they hit the actual landing page
                        navigate('/');
                        return;
                    }

                    navigate('/get-in-touch');
                }),
        silentRenew: () =>
            authService.userManager!.signinSilentCallback().catch((error) => {
                console.error(error);
            }),
        decodeJwt: (code: string) => JSON.parse(atob(code.split('.')[1])),
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
