import { createTheme } from '@material-ui/core/styles';
import type { AlertClassKey } from '@material-ui/lab/Alert';

declare module '@material-ui/core/styles/overrides' {
    export interface ComponentNameToClassKey {
        MuiAlert: AlertClassKey;
    }
}

const defaultTheme = createTheme();

const toolbar = {
    [defaultTheme.breakpoints.only('xs')]: {
        minHeight: '80px',
    },
    [defaultTheme.breakpoints.up('sm')]: {
        minHeight: '64px',
    },
};

// A custom theme for this app
const theme = createTheme({
    props: {
        MuiTextField: {
            margin: 'normal',
            InputLabelProps: {
                shrink: true,
            },
        },
    },
    mixins: {
        toolbar: {
            ...toolbar,
        },
    },
    palette: {
        destructive: {},
        tableHeader: {},
        text: {
            primary: '#291b44',
        },
        primary: {
            main: '#291b44',
            light: '#c8bcd8',
        },
        secondary: {
            main: '#00C9AF',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: ['Spartan MB Regular', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
});
theme.overrides = {
    MuiAlert: {
        root: {
            borderRadius: '8px',
        },
        filledError: {
            backgroundColor: '#C9003C',
        },
        filledSuccess: {
            backgroundColor: '#00C9AF',
        },
        icon: {
            [defaultTheme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(2.5),
            },
            alignItems: 'center',
        },
        message: {
            flexGrow: 1,
            textAlign: 'left',
            padding: theme.spacing(1),
            [defaultTheme.breakpoints.up('sm')]: {
                padding: theme.spacing(1, 0),
                textAlign: 'center',
            },
        },
    },
    MuiAppBar: {
        root: {
            [defaultTheme.breakpoints.only('xs')]: {
                minHeight: '64px',
            },
            justifyContent: 'center',
        },
        colorPrimary: {
            backgroundColor: '#291b44',
        },
    },
    MuiButton: {
        root: {
            color: '#9d9d9d',
            fontSize: '0.875rem',
            fontFamily: 'Spartan MB Regular',
            textTransform: 'none',
            borderRadius: '30px',
            width: '100%',
            padding: '15px',
            lineHeight: '1rem',
            height: '50px',
            '& .alertEndIcon': {
                borderColor: '#fff',
                backgroundColor: '#fff',
                '& .rectangle, .ellipse': {
                    backgroundColor: '#00c9af',
                },
            },
        },
        outlined: {
            border: 'none',
            padding: '10px',
            backgroundColor: '#fff',
            color: '#000',
            '&:focus, &:hover': {
                backgroundColor: '#0000001a',
            },
        },
        outlinedPrimary: {
            border: '1px solid #00c9af',
            color: '#00c9af',
            '&:focus, &:hover': {
                backgroundColor: '#00c9af1a',
                border: '1px solid #00c9af',
            },
        },
        outlinedSecondary: {
            border: '1px solid #291b44',
            color: '#291b44',
            '&:focus, &:hover': {
                backgroundColor: '#291b441a',
                border: '1px solid #291b44',
            },
        },
        contained: {
            boxShadow: '0px 9px 8px #291b4438',
            backgroundColor: '#fff',
            '&:hover': {
                backgroundColor: '#00C9AF',
                color: '#fff',
            },
        },
        containedPrimary: {
            backgroundColor: '#00c9af',
            boxShadow: '0px 9px 8px #00c9af1a',
            padding: '10px',
            '&:focus, &:hover': {
                backgroundColor: '#00c9af',
            },
            '&:hover': {
                '@media (hover:none)': {
                    backgroundColor: '#00c9af',
                },
            },
        },
        containedSecondary: {
            backgroundColor: '#291b44',
            '&:focus, &:hover': {
                boxShadow: '0px 9px 8px #291b4438',
                backgroundColor: '#291b44',
            },
        },
        textPrimary: {
            textDecoration: 'underline',
            [defaultTheme.breakpoints.up('sm')]: {
                textDecoration: 'none',
            },
            '&:hover': {
                textDecoration: 'underline',
                backgroundColor: 'transparent',
            },
        },
        label: {
            paddingLeft: '10px',
            paddingRight: '5px',
            display: 'flex',
            '& span:first-child': {
                flex: '1',
            },
        },
    },
    MuiDialog: {
        paperWidthXs: {
            maxWidth: '400px',
        },
        paperWidthSm: {
            maxWidth: '900px',
        },
        paper: {
            [defaultTheme.breakpoints.up('sm')]: {
                padding: '25px 130px',
            },
        },
    },
    MuiDivider: {
        root: {
            color: '#5F5573',
        },
    },
    MuiInputLabel: {
        root: {
            position: 'inherit',
        },
        shrink: {
            transform: 'scale(1)',
        },
    },
    MuiListItemText: {
        root: {
            paddingTop: '0px',
        },
    },
    MuiToolbar: {
        root: {
            ...toolbar,
        },
    },
    MuiTypography: {
        root: {
            color: '#291b44',
        },
        body1: {
            fontFamily: 'Spartan MB Regular',
            fontSize: '0.875rem',
        },
        h1: {
            fontFamily: 'GT Walsheim Pro Bold',
            fontSize: '1.875rem',
            textAlign: 'center',
        },
        h2: {
            fontFamily: 'GT Walsheim Pro Bold',
            fontSize: '1.5rem',
            textAlign: 'center',
        },
        h3: {
            fontFamily: 'GT Walsheim Pro Bold',
            fontSize: '1.125rem',
        },
        h4: {
            fontFamily: 'GT Walsheim Pro Bold',
            fontSize: '1rem',
        },
        h5: {
            fontFamily: 'GT Walsheim Pro Bold',
            fontSize: '0.938rem',
        },
        h6: {
            fontFamily: 'GT Walsheim Pro Bold',
            fontSize: '0.875rem',
        },
    },
    MuiPaper: {
        rounded: {
            borderRadius: 5,
        },
        elevation0: {
            padding: '1.5rem',

            [defaultTheme.breakpoints.up('sm')]: {
                padding: '3rem',
            },
            filter: 'drop-shadow(0px 9px 8px rgba(152,184,190,0.31))',
            boxShadow: 'none',
        },
    },
};
export default theme;
