import { types, flow, SnapshotIn } from 'mobx-state-tree';
import ApiStatus from 'state/enums/ApiStatus';
import axios from 'axios';
import { renewalsQueryService } from 'services/renewalsQueryService';
import { renewalsService } from 'services/renewalsService';
export const OptedInToDigitalDocumentsStore = types
    .model({
        optedInToDigitalDocuments: types.boolean,
        getOptedInApiStatus: types.enumeration<ApiStatus>('ApiStatus', Object.values(ApiStatus)),
        setOptedInApiStatus: types.enumeration<ApiStatus>('ApiStatus', Object.values(ApiStatus)),
        showOptIn: types.boolean,
    })
    .actions((self) => ({
        setOptedInToDigitalDocuments(optedIn: boolean) {
            self.optedInToDigitalDocuments = optedIn;
        },
    }))
    .actions((self) => ({
        fetchOptedInToDigitalDocuments: flow(function* fetchOptedInToDigitalDocuments() {
            try {
                const api = renewalsQueryService();
                const response = yield api.apiRenewalsOptInGet();
                const result = response.data as boolean;

                if (response.status === 200) {
                    self.getOptedInApiStatus = ApiStatus.Success;
                    self.setOptedInToDigitalDocuments(result);
                    self.showOptIn = !self.optedInToDigitalDocuments;
                } else {
                    throw new Error('getRenewalDetails was not successful');
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    self.getOptedInApiStatus = ApiStatus.Error;
                }
            }
        }),
        changeOptedInToDigitalDocuments: flow(function* changeOptedInToDigitalDocuments(
            optedIn: boolean,
            linkSource: string,
        ) {
            try {
                self.setOptedInApiStatus = ApiStatus.Pending;
                const api = renewalsService();

                const response = yield api.apiRenewalsOptInPost({
                    OptedIn: optedIn,
                    LinkSource: linkSource,
                });

                if (response.status === 200) {
                    self.setOptedInApiStatus = ApiStatus.Success;
                    self.setOptedInToDigitalDocuments(optedIn);
                    self.showOptIn = false;
                } else {
                    throw new Error('change optin choice was not successful');
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    self.setOptedInApiStatus = ApiStatus.Error;
                }
            }
        }),
    }))
    .views((self) => ({
        get setOptInChoiceHasError(): boolean {
            return self.setOptedInApiStatus === ApiStatus.Error;
        },
    }));

export interface IOptedInToDigitalDocuments extends SnapshotIn<typeof OptedInToDigitalDocumentsStore> {}

export const optedInToDigitalDocumentsInitialState: IOptedInToDigitalDocuments = {
    getOptedInApiStatus: ApiStatus.Pending,
    setOptedInApiStatus: ApiStatus.Pending,
    optedInToDigitalDocuments: false,
    showOptIn: false,
};
