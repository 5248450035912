import { Field as FormikField, FieldAttributes, FieldProps } from 'formik';

// This is a wrapper for Formik Field and should be used in place of the Field component when implementing a Formik form
// It is intended for use with Material UI components which support the error and helperText props

function FormikFieldWrapper({ validate, name, as: is, component: Component, ...props }: FieldAttributes<any>): any {
    const id = (props && props.id) || 'formik-field-wrapper';
    return (
        <FormikField id={id} name={name} validate={validate} is={is}>
            {({ field, meta: { error } }: FieldProps) => {
                const hasError = !!error;

                return <Component error={hasError} helperText={error || ''} {...field} {...props} />;
            }}
        </FormikField>
    );
}

export { FormikFieldWrapper as Field };
