import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import AddressModel from './AddressModel';

export const Address = types
    .model({
        addressLine1: types.string,
        addressLine2: types.string,
        addressLine3: types.string,
        addressLine4: types.string,
        postcode: types.string,
    })
    .actions((self) => ({
        setAddress(address: AddressModel) {
            self.addressLine1 = address.AddressLine1;
            self.addressLine2 = address.AddressLine2;
            self.addressLine3 = address.AddressLine3;
            self.addressLine4 = address.AddressLine4;
            self.postcode = address.Postcode;
        },
    }))
    .views((self) => {
        const views = {
            get fullAddressInline(): string {
                const address = [
                    self.addressLine1,
                    self.addressLine2,
                    self.addressLine3,
                    self.addressLine4,
                    self.postcode,
                ];
                const availableFields = address.filter((line) => !!line);

                return availableFields.join(', ');
            },
        };

        return views;
    });

export const addressInitialState: IAddress = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postcode: '',
};

export interface IAddress extends SnapshotIn<typeof Address> {}
export interface IAddressStore extends Instance<typeof Address> {}
