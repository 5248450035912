import axios, { CancelTokenSource, CancelTokenStatic } from 'axios';
import { applySnapshot, flow, SnapshotIn, types } from 'mobx-state-tree';
import { RenewalDetails } from '../api-clients/customer-portal/api';
import { Address, addressInitialState } from './models/common/AddressStore';
import { ApplicantDetails, applicantDetailsInitialState, IApplicant } from './models/common/ApplicantStore';
import ApiStatus from './enums/ApiStatus';
import { QuotePremium, quotePremiumInitialState } from './models/home-insurance/QuotePremiumStore';
import { CoverOptions, coverOptionsInitialState } from './models/home-insurance/CoverOptionsStore';
import { PropertyDetails, propertyDetailsInitialState } from './models/home-insurance/PropertyDetailsStore';
import { ISpecifiedItem } from './models/home-insurance/SpecifiedItemStore';
import {
    optedInToDigitalDocumentsInitialState,
    OptedInToDigitalDocumentsStore,
} from 'state/OptedInToDigitalDocumentsStore';
import { renewalsQueryService } from 'services/renewalsQueryService';

export const HomeInsuranceQuote = types
    .model({
        applicantDetails: ApplicantDetails,
        riskAddress: Address,
        propertyDetails: PropertyDetails,
        quotePremium: QuotePremium,
        coverOptions: CoverOptions,
        quoteReference: types.string,
        correspondenceAddress: Address,
        policyStartDate: types.maybe(types.Date),
        optedInToDigitalDocuments: OptedInToDigitalDocumentsStore,
        doNotAutoRenew: types.boolean,
        getRenewalDetailsApiStatus: types.enumeration<ApiStatus>('ApiStatus', Object.values(ApiStatus)),
        renewalInceptionDate: types.maybe(types.Date),
    })
    .actions((self) => ({
        setQuoteData(data: RenewalDetails) {
            const applicant2: IApplicant | undefined = data.Client2
                ? {
                      title: data.Client2.Title || '',
                      firstName: data.Client2.FirstName || '',
                      lastName: data.Client2.LastName || '',
                      dateOfBirth: data.Client2.DateOfBirth ? new Date(data.Client2.DateOfBirth) : undefined,
                  }
                : undefined;

            const specifiedItems: ISpecifiedItem[] =
                (data.ContentsSpecifiedItems &&
                    data.ContentsSpecifiedItems.map((item) => ({
                        type: item.Type || '',
                        description: item.Description || '',
                        value: item.Value || 0,
                        coverAtHome: item.CoverAtHome || false,
                        coverAwayFromHome: item.CoverAwayFromHome || false,
                    }))) ||
                [];

            applySnapshot(self, {
                ...self,
                applicantDetails: {
                    applicant1: {
                        title: data.Client1?.Title || '',
                        firstName: data.Client1?.FirstName || '',
                        lastName: data.Client1?.LastName || '',
                        dateOfBirth: data.Client1?.DateOfBirth ? new Date(data.Client1.DateOfBirth) : undefined,
                        emailAddress: data.Client1?.EmailAddress || '',
                        telephoneNumber: data.Client1?.TelephoneNumber || '',
                    },
                    applicant2: applicant2,
                },
                riskAddress: {
                    addressLine1: data.RiskAddress?.AddressLine1 || '',
                    addressLine2: data.RiskAddress?.AddressLine2 || '',
                    addressLine3: data.RiskAddress?.AddressLine3 || '',
                    addressLine4: data.RiskAddress?.AddressLine4 || '',
                    postcode: data.RiskAddress?.Postcode || '',
                },

                propertyDetails: {
                    numberOfBedrooms: data.NumberOfBedrooms || 0,
                    propertyType: data.PropertyType || '',
                    yearBuiltRange: data.YearOfConstructionRange || '',
                },

                coverOptions: {
                    buildingsIncluded: data.BuildingsCoverIncluded || false,
                    buildingsAccidentalDamageIncluded: data.BuildingsAccidentalDamageIncluded || false,
                    buildingsClaimFreeYears: data.BuildingsNoClaimsYears || 0,
                    buildingsExcess: data.BuildingsExcess || 0,
                    buildingsCoverLimit: data.BuildingsCoverLimit || 0,

                    contentsIncluded: data.ContentsCoverIncluded || false,
                    contentsAccidentalDamageIncluded: data.ContentsAccidentalDamageIncluded || false,
                    contentsClaimFreeYears: data.ContentsNoClaimsYears || 0,
                    contentsExcess: data.ContentsExcess || 0,
                    contentsCoverLimit: data.ContentsCoverLimit || 0,
                    contentsPersonalPossessionsValue: data.ContentsPersonalPossessionsValue || 0,

                    legalProtectionIncluded: data.FamilyLegalProtection || false,
                    homeEmergencyCoverIncluded: data.HomeEmergencyCover || false,

                    contentsSpecifiedItems: specifiedItems,
                },

                quotePremium: {
                    provider: data.QuotePremium?.Provider || '',
                    creditCharge: data.QuotePremium?.CreditCharge || 0,
                    totalAmountPayable: data.QuotePremium?.TotalAmountPayable || 0,
                    totalMonthlyPremium: data.QuotePremium?.TotalMonthlyPremium || 0,
                    lastYearsMonthlyPremium: data.QuotePremium?.LastYearsMonthlyPremium || 0,
                    lastYearsPremium: data.QuotePremium?.LastYearsPremium || 0,
                    lastYearsPremiumWithoutPremiumFunding:
                        data.QuotePremium?.LastYearsPremiumWithoutPremiumFunding || 0,
                    isPayingMonthly: data.QuotePremium?.IsPayingMonthly || false,
                    isPremiumFunded: data.QuotePremium?.IsPremiumFunded || false,
                },
                policyStartDate: data.PolicyStartDate ? new Date(data.PolicyStartDate) : undefined,
                quoteReference: data.QuoteReference || '',
                correspondenceAddress: {
                    addressLine1: data.CorrespondenceAddress?.AddressLine1 || '',
                    addressLine2: data.CorrespondenceAddress?.AddressLine2 || '',
                    addressLine3: data.CorrespondenceAddress?.AddressLine3 || '',
                    addressLine4: data.CorrespondenceAddress?.AddressLine4 || '',
                    postcode: data.CorrespondenceAddress?.Postcode || '',
                },
                doNotAutoRenew: data.DoNotAutoRenew || false,
                renewalInceptionDate: data.RenewalInceptionDate ? new Date(data.RenewalInceptionDate) : undefined,
            });
        },
    }))
    .actions((self) => {
        let cancelToken: CancelTokenStatic;
        let source: CancelTokenSource;

        return {
            afterCreate() {
                cancelToken = axios.CancelToken;
            },
            getRenewalDetails: flow(function* getRenewalDetails() {
                if (source && source.token) {
                    source.cancel();
                }

                source = cancelToken.source();

                try {
                    var api = renewalsQueryService();

                    const response = yield api.apiRenewalsDetailsGet();
                    const results = response.data as RenewalDetails;

                    if (response.status === 200) {
                        self.getRenewalDetailsApiStatus = ApiStatus.Success;
                        self.setQuoteData(results);
                    } else {
                        throw new Error('getRenewalDetails was not successful');
                    }
                } catch (error) {
                    console.error(error);
                    if (!axios.isCancel(error)) {
                        self.getRenewalDetailsApiStatus = ApiStatus.Error;
                    }
                }
            }),
        };
    })
    .views((self) => {
        const views = {
            get estimatedFirstPaymentDate(): string {
                if (self.renewalInceptionDate) {
                    const renewalDate: Date = new Date(self.renewalInceptionDate);
                    let earliestPaymentDate: Date = new Date(self.renewalInceptionDate);
                    const renewalDay = self.renewalInceptionDate.getDay();
                    switch (renewalDay) {
                        case 6:
                            earliestPaymentDate = renewalDate.addDays(2);
                            break;
                        case 0:
                            earliestPaymentDate = renewalDate.addDays(1);
                            break;
                    }

                    return earliestPaymentDate.formatAsDdmmyyyy();
                }

                throw new Error('Renewal inception date not available.');
            },
            get accountReference(): string {
                if (self.quoteReference) {
                    const renewalSeparator = self.quoteReference.indexOf('/');
                    if (renewalSeparator > -1) {
                        return self.quoteReference.substring(0, renewalSeparator);
                    }

                    return self.quoteReference;
                }

                throw new Error('Quote reference not available.');
            },
        };

        return views;
    });

export interface IHomeInsuranceQuote extends SnapshotIn<typeof HomeInsuranceQuote> {}

export const homeInsuranceQuoteInitialState: IHomeInsuranceQuote = {
    applicantDetails: ApplicantDetails.create(applicantDetailsInitialState),
    riskAddress: Address.create(addressInitialState),
    coverOptions: CoverOptions.create(coverOptionsInitialState),
    correspondenceAddress: Address.create(addressInitialState),
    quotePremium: QuotePremium.create(quotePremiumInitialState),
    policyStartDate: Date.now(),
    quoteReference: '',
    propertyDetails: PropertyDetails.create(propertyDetailsInitialState),
    getRenewalDetailsApiStatus: ApiStatus.Pending,
    optedInToDigitalDocuments: OptedInToDigitalDocumentsStore.create(optedInToDigitalDocumentsInitialState),
    doNotAutoRenew: false,
    renewalInceptionDate: Date.now(),
};
