import { Configuration, RenewalsApiFactory } from 'api-clients/customer-portal';
import { getToken, PORTAL_API } from './tokenService';

export const renewalsService = () => {
    const api = RenewalsApiFactory(
        new Configuration({
            accessToken: getToken(),
        }),
        PORTAL_API,
    );
    return api;
};
