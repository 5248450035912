import { useMediaQuery, useTheme } from '@material-ui/core';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const useMobile = (breakpoint: Breakpoint = 'md') => {
    const theme = useTheme();
    const isMobileDevice = useMediaQuery(theme.breakpoints.down(breakpoint));

    return { isMobileDevice };
};
