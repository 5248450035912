import axios, { AxiosRequestConfig, CancelTokenSource, CancelTokenStatic } from 'axios';
import { flow, SnapshotIn, types } from 'mobx-state-tree';
import { renewalsQueryService } from 'services/renewalsQueryService';

export const DownloadStore = types
    .model({
        isLoading: types.boolean,
        hasError: types.boolean,
    })
    .actions((self) => {
        let cancelToken: CancelTokenStatic;
        let source: CancelTokenSource;

        return {
            afterCreate() {
                cancelToken = axios.CancelToken;
            },
            downloadRenewal: flow(function* downloadRenewal(policyNumber: string, surname: string) {
                if (source && source.token) {
                    source.cancel();
                }

                source = cancelToken.source();

                try {
                    self.isLoading = true;
                    self.hasError = false;
                    const axiosConfig: AxiosRequestConfig = {
                        responseType: 'blob',
                    };

                    const api = renewalsQueryService();
                    yield api.apiRenewalsDownloadGet(axiosConfig).then((blob) => {
                        var url = window.URL.createObjectURL(new Blob([blob.data]));
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = `${policyNumber.replace('/', '-')}-${surname}-Renewal-Pack.pdf`;
                        a.click();
                        a.remove();
                        setTimeout(() => window.URL.revokeObjectURL(url), 100);
                    });
                } catch (error: any) {
                    if (error.message != 'Operation canceled by the user.') {
                        self.isLoading = false;
                    }
                    self.hasError = true;
                } finally {
                    self.isLoading = false;
                }
            }),
        };
    });

export const downloadInitialState: IDownload = {
    isLoading: false,
    hasError: false,
};
export interface IDownload extends SnapshotIn<typeof DownloadStore> {}
