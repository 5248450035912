import { Container, createStyles, CssBaseline, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        offset: {
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(6),
            },
            backgroundColor: '#fff',
        },
        layout: {
            paddingBottom: theme.spacing(3),
        },
    });
});

interface Props {
    children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
    const classes = useStyles();

    return (
        <>
            <CssBaseline />
            <Header />
            <Container maxWidth={'md'} className={`${classes.offset} ${classes.layout}`}>
                <>{children}</>
            </Container>
        </>
    );
};

export default Layout;
