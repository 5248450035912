import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setSessionStorage } from 'services/sessionStorageService';
import { getParam } from 'services/urlParamService';

const LinkSourceStore = () => {
    let location = useLocation();

    useEffect(() => {
        const utm_source = getParam('utm_source', location);
        if (utm_source !== null) {
            setSessionStorage('utm_source', utm_source);
        }
    }, []);

    return <></>;
};

export default LinkSourceStore;
