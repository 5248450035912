import { applySnapshot, Instance, onSnapshot, types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { HomeInsuranceQuote, homeInsuranceQuoteInitialState } from './HomeInsuranceQuoteStore';
import { authenticationInitialState, AuthenticationStore } from './authentication';
import { downloadInitialState, DownloadStore } from './DownloadStore';
import { customerContactInitialState, CustomerContactStore } from './CustomerContactDetailsStore';

export const RootStore = types.model({
    authentication: AuthenticationStore,
    homeInsuranceQuote: HomeInsuranceQuote,
    download: DownloadStore,
    customerContact: CustomerContactStore,
});

const rootStoreName = 'rootStore';

const rootStoreInitialState = {
    authentication: authenticationInitialState,
    homeInsuranceQuote: homeInsuranceQuoteInitialState,
    download: downloadInitialState,
    customerContact: customerContactInitialState,
};

const rootStoreState = RootStore.create(rootStoreInitialState);

if (typeof window !== 'undefined') {
    const localStore = localStorage.getItem(rootStoreName);
    const sessionStore = sessionStorage.getItem(rootStoreName);

    let state = rootStoreInitialState;

    if (localStore) {
        const persistedState = JSON.parse(localStore);
        state = {
            ...state,
            ...persistedState,
        };
    }

    if (sessionStore) {
        const persistedState = JSON.parse(sessionStore);
        state = {
            ...state,
            ...persistedState,
        };
    }

    try {
        applySnapshot(rootStoreState, state);
    } catch {
        localStorage.removeItem(rootStoreName);
    }
}

// https://mobx-state-tree.js.org/concepts/snapshots
// Snapshots create an immutable serialization of a tree at any given point in time.
// No type information in contained and they are stripped of actions
// As demonstrated below, snapshots can be used to update or restore models
// onSnapshot creates a listener for a model which fires whenever a new snapshot is available
// upon finishing a mobX transaction
onSnapshot(rootStoreState, (snapshot: any) => {
    if (typeof window !== 'undefined') {
        let { authentication, rest } = snapshot;
        localStorage.setItem(rootStoreName, JSON.stringify({ ...rest } || {}));
        sessionStorage.setItem(rootStoreName, JSON.stringify({ authentication }));
    }
});

// This allows us to use our mobX model as a Typescript interface for compile time checks
export interface IRootStore extends Instance<typeof RootStore> {}

// Create context for our store which we can pass to our context provider
const RootStoreContext = createContext<IRootStore>({} as any);
// Define a hook which allows us to access and use our store
const useRootStoreContext = () => useContext(RootStoreContext);

export { rootStoreState, RootStoreContext, useRootStoreContext };
