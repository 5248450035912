import * as Yup from 'yup';

const ContactDetailsValidationSchema = Yup.object({
    emailAddress: Yup.string()
        .required('Email address is required')
        .matches(
            /^(([^<>()\[\]\\.,;:\s@\"]+(\.[^<>()\[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            'Email address must be valid',
        ),
});

export default ContactDetailsValidationSchema;
