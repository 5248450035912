import { Box, Button, Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import { Dialog, IconHeading, TextField } from '@uinsure/storybook';
import GoPaperlessBackground from 'assets/go-paperless-envelope.png';
import { Field } from 'components/formik-material-ui/FormikField';
import Header from 'components/Header';
import { Form, Formik } from 'formik';
import { useMobile } from 'hooks/useMobile';
import contactDetailsValidationSchema from '../forms/validation/ContactDetailsValidationSchema';

interface CustomerContactDetailsModalProps {
    isOpen: boolean;
    emailAddress: string;
    onSubmit: (values: CustomerContactDetailsFormModel) => void;
}

export interface CustomerContactDetailsFormModel {
    emailAddress: string;
}

const useStyles = makeStyles({
    emailAddressInput: {
        marginTop: '14px',
        '& input': {
            textAlign: 'center',
        },
    },
});

const CustomerContactDetailsModal = ({
    isOpen,
    emailAddress,
    onSubmit: handleSubmit,
}: CustomerContactDetailsModalProps) => {
    const { isMobileDevice } = useMobile('xs');

    const hasEmailAddress = !!emailAddress;
    const emailAddressLabel = hasEmailAddress ? 'We will send your documents to' : 'Please provide your email address';
    const classes = useStyles();
    return (
        <Dialog open={isOpen} fullScreen={isMobileDevice}>
            <Hidden smUp>
                <Header />
            </Hidden>
            <IconHeading headingText='Go Paperless' imageSource={GoPaperlessBackground} />
            <Box pt={4} pb={3} width='100%'>
                <Typography data-testid='go-paperless-message' align='center'>
                    Thank you for going paperless!
                </Typography>
            </Box>
            <Formik
                initialValues={{
                    emailAddress: emailAddress,
                }}
                validationSchema={contactDetailsValidationSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <Box px={3} pt={3} pb={2}>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12}>
                                <Typography data-testid='email-address-label' align='center'>
                                    <strong>{emailAddressLabel}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={9} md={5}>
                                <Field
                                    id='email-address'
                                    name='emailAddress'
                                    fullWidth
                                    placeholder='Please enter your email here'
                                    component={TextField}
                                    inputProps={{
                                        'data-testid': 'email-address',
                                    }}
                                    inputRef={(input: HTMLInputElement) => {
                                        if (input !== null) {
                                            input.focus();
                                        }
                                    }}
                                    className={classes.emailAddressInput}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container justifyContent='center'>
                        <Grid item xs={6} md={4}>
                            <Button data-testid='confirm-button' color='primary' variant='contained' type='submit'>
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default CustomerContactDetailsModal;
