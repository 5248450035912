import { Configuration, RenewalsApiFactory } from 'api-clients/customer-portal';
import { getToken, PORTAL_API } from './tokenService';

export const customerContactService = () => {
    const api = RenewalsApiFactory(
        new Configuration({
            accessToken: getToken(),
        }),
        PORTAL_API,
    );
    return api;
};

const emailConfirmedCookieName = 'email_confirmed';

export const hasConfirmedEmailAddress = (): boolean => {
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${emailConfirmedCookieName}=`));
};

export const setEmailAddressConfirmedCookie = (renewalDate?: Date): void => {
    var cookie = `${emailConfirmedCookieName}=true; secure`;
    if (renewalDate) {
        cookie += ';expires=' + (renewalDate as Date).addDays(14).toUTCString();
    }
    document.cookie = cookie;
};
