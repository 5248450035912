import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useMobile } from '../hooks/useMobile';
import { Box, Button, createStyles, Dialog, Hidden, makeStyles, Theme } from '@material-ui/core';
import { BulletLabel, IconHeading } from '@uinsure/storybook';
import GoPaperlessBackground from 'assets/go-paperless.png';
import Header from './Header';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            '& li': {
                alignItems: 'start',
                '& span': {
                    fontSize: '0.9rem',
                },
            },
            '& svg': {
                position: 'relative',
                top: '8px',
            },
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(2),
            },
        },
        buttonContainer: {
            [theme.breakpoints.down('sm')]: {
                maxWidth: '200px',
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: '230px',
            },
            marginLeft: 'auto',
            marginRight: 'auto',
            '& button': {
                marginBottom: '10px',
            },
        },
    }),
);

interface OptInProps {
    onOptInChoice: (optedIn: boolean) => void;
    settingOptInChoiceHasError: boolean;
    isOpen: boolean;
}

const OptIn = (props: OptInProps) => {
    const optInDialogMobileBreakpoint = 'xs';
    const { onOptInChoice, settingOptInChoiceHasError, isOpen } = props;
    const { isMobileDevice } = useMobile(optInDialogMobileBreakpoint);

    const handleOptInClick = (optedIn: boolean) => {
        onOptInChoice(optedIn);
    };

    const classes = useStyles();

    return (
        <Dialog data-testid='opt-in-dialog' open={isOpen} fullScreen={isMobileDevice}>
            <Hidden smUp>
                <Header />
            </Hidden>
            <Box className={classes.dialogContent}>
                <Box textAlign={'center'}>
                    <IconHeading headingText='Go Paperless' imageSource={GoPaperlessBackground} />
                    <BulletLabel labelText='A safe, secure and handy way to access all your important documents – 24/7 – securely, and in one place.' />
                    <BulletLabel labelText='Reduce the clutter, with all your documents stored in a safe online place – you can search easily for specific documents.' />
                    <BulletLabel
                        labelText={`We'll send you an email and text reminder any time you have new documents to view.`}
                    />
                    <BulletLabel labelText='Renewal documents sent via email or text will reach you quicker.' />
                </Box>
                <Box textAlign='center' className={classes.buttonContainer}>
                    {settingOptInChoiceHasError && (
                        <Typography color='error' align='center' data-testid='optin-error-text'>
                            Unable to record communication preference, please try again
                        </Typography>
                    )}
                    <Box textAlign='center' onClick={() => handleOptInClick(true)}>
                        <Button variant='contained' color='primary' data-testid='go-paperless-button'>
                            Go paperless
                        </Button>
                    </Box>
                    <Box textAlign='center' onClick={() => handleOptInClick(false)}>
                        <Button variant='outlined' color='primary' data-testid='opt-out-button'>
                            Post my documents
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
};

export { OptIn };
