interface UseUnderwriterProps {
    underwriterName: string;
}

interface UnderwriterSummary {
    uri?: string;
    name: string;
}

const useUnderwriter = ({ underwriterName }: UseUnderwriterProps): UnderwriterSummary => {
    const baseUri = '/img/underwriters';
    switch (underwriterName) {
        case 'Ageas Insurance Ltd':
            return { uri: `${baseUri}/ageas.png`, name: underwriterName };
        case 'AXA Insurance UK plc':
            return { uri: `${baseUri}/axa.png`, name: underwriterName };
        case 'Covea Insurance plc':
            return { uri: `${baseUri}/covea.png`, name: underwriterName };
        case 'LV= Broker':
            return { uri: `${baseUri}/lv.png`, name: underwriterName };
        case 'RSA Insurance Group Ltd':
            return { uri: `${baseUri}/rsa.png`, name: underwriterName };
        case 'UK General on behalf of Watford Insurance Company Europe Ltd':
            return { uri: `${baseUri}/ukgeneral.png`, name: underwriterName };
        case 'Zurich Insurance Company Ltd':
            return { uri: `${baseUri}/zurich.png`, name: underwriterName };
        default:
            return { name: underwriterName };
    }
};

export default useUnderwriter;
