import { Box, Card, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useUnderwriter from 'hooks/useUnderwriter';

interface UnderwrittenByProps {
    underwriterKey: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    underwrittenBy: {
        '&:first-child': { marginBottom: theme.spacing(1) },
    },
    logo: {
        height: '45px',
    },
    logoCard: {
        borderWidth: '2px',
        borderRadius: '12px',
        borderColor: theme.palette.divider,
        padding: theme.spacing(1),
    },
    [theme.breakpoints.only('xs')]: {
        underwrittenBy: {
            fontSize: '0.75rem',
            '&:first-child': { marginBottom: theme.spacing(3) },
        },
        logo: {
            height: '50px',
        },
        logoCard: {
            height: '100px',
            minWidth: '100px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            padding: theme.spacing(0, 1),
        },
    },
}));

export const UnderwrittenBy = (props: UnderwrittenByProps) => {
    const { underwriterKey } = props;
    const { name, uri } = useUnderwriter({ underwriterName: underwriterKey });
    const classes = useStyles();

    return (
        <Grid container spacing={3} alignItems='center'>
            <Grid item xs data-testid='underwritten-text'>
                <Typography className={classes.underwrittenBy}>
                    Please ensure you read through your renewal information below.
                </Typography>
                <Typography className={classes.underwrittenBy}>
                    This year, your Uinsure policy is underwritten by {name}.
                </Typography>
            </Grid>
            <Grid item>
                <Box width='100%' justifyContent='center' display='flex' alignItems='center'>
                    <Card variant='outlined' className={classes.logoCard}>
                        <Box display='flex' alignItems='center' p={1}>
                            <img className={classes.logo} src={uri} alt={name} data-testid='underwriter-logo' />
                        </Box>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};

export default UnderwrittenBy;
