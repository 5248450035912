import LinkSourceStore from 'components/LinkSourceStore';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RequireAuth from './components/requireAuth';
import ParseCode from './pages/code';
import GetInTouch from './pages/getInTouch';
import SigninCallback from './pages/signinCallback';
import Summary from './pages/summary';
import { AuthProvider } from './services/authProvider';

function App() {
    return (
        <BrowserRouter>
            <LinkSourceStore />
            <AuthProvider>
                <Routes>
                    <Route path='/get-in-touch' element={<GetInTouch />} />
                    <Route element={<SigninCallback />} path='/signin-callback/:code' />
                    <Route element={<SigninCallback />} path='/signin-callback/*' />
                    <Route element={<ParseCode />} path='/login/:code/*' />

                    <Route path='/' element={<RequireAuth />}>
                        <Route path='/hh/summary' element={<Summary />} />
                    </Route>
                    <Route path='' element={<GetInTouch />} />
                    <Route path='*' element={<Navigate to='/get-in-touch' />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
