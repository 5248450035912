import axios from 'axios';
import { ClockService, Log, User, UserManager, UserManagerSettings } from 'oidc-client';
import { isBrowser } from './auth';

const stsAuthority = process.env.REACT_APP_ISSUER_URL;
const clientId = 'customer-portal-spa';
const clientRoot = process.env.REACT_APP_CUSTOMER_PORTAL_APP;
const clientScope = 'openid profile email uinsure.api.customer.read uinsure.api.customer.write IdentityServerApi';

export class AuthService {
    public userManager?: UserManager;

    constructor(code?: any) {
        const callbackPath = 'signin-callback';

        const redirectUri = `${clientRoot}/${callbackPath}/${code}`;

        // If a code is passed we need to force the login to kill any previous session
        const requireLogin = !!code;

        let settings: UserManagerSettings = {
            authority: stsAuthority,
            client_id: clientId,
            redirect_uri: redirectUri,
            silent_redirect_uri: `${clientRoot}/silent-renew`,
            post_logout_redirect_uri: `${clientRoot}`,
            response_type: 'code',
            scope: clientScope,
            automaticSilentRenew: true,
            ...(requireLogin && {
                prompt: 'login',
            }),
            clockService: new ClockServce(),
        };

        if (code) {
            settings = {
                ...settings,
                extraQueryParams: {
                    portalcode: code,
                },
            };
        }

        this.userManager = isBrowser() ? new UserManager(settings) : undefined;

        if (isBrowser()) {
            Log.logger = console;
            Log.level = Log.INFO;

            if (this.userManager) {
                this.userManager.events.addAccessTokenExpired(() => {
                    this.logout();
                });

                this.userManager.events.addSilentRenewError(() => {
                    this.logout();
                });
            }
        }
    }

    public getUser(): Promise<User | null> {
        return this.userManager ? this.userManager.getUser() : Promise.resolve(null);
    }

    public login(): Promise<void> {
        if (isBrowser()) {
            localStorage.removeItem('persist:root');
        }
        return this.userManager ? this.userManager.signinRedirect() : Promise.resolve();
    }

    public removeUser() {
        return this.userManager?.removeUser();
    }

    public renewToken(): Promise<User | void> {
        return this.userManager ? this.userManager.signinSilent() : Promise.resolve();
    }

    public logout(): Promise<void> {
        return this.userManager ? this.userManager.signoutRedirect() : Promise.resolve();
    }
}

class ClockServce implements ClockService {
    getEpochTime(): Promise<number> {
        return new Promise(async (resolve, reject) => {
            var result = await axios.get(`${stsAuthority}/api/epoch`);
            if (result.status == 200) {
                resolve(result.data);
            } else {
                reject('Failed to get epoch from server');
            }
        });
    }
}
